import * as prismic from '@prismicio/client'
import cookie from 'js-cookie'
import { EventEmitter } from './EventEmitter'
import { getPreview } from './Preview'
import { Router } from './Router'
import { repo_name } from '../../config.json'
import { getScrollbarWidth } from '../utils/getScrollbarWidth'

import './Html.module.css'
import './Home.module.css'
import './BackButton.module.css'
import './Copy.module.css'

import styles from './Html.module.css'
import navigationStyles from './Navigation.module.css'
import footerStyles from './Footer.module.css'
import speakerStyle from './Speaker.module.css'
import speakersStyle from './Speakers.module.css'
import introStyles from './Intro.module.css'
import speakerArchiveStyles from './SpeakerArchive.module.css'
import anchorStyles from './Anchor.module.css'
import headingStyles from './Heading.module.css'
import errorStyles from './Error.module.css'
import timeTableStyles from './Timetable.module.css'
import awardStyles from './Award.module.css'

import { SpeakersController } from './SpeakersController'
import { SpeakerController } from './SpeakerController'
import { IntroController } from './IntroController'
import { FooterController } from './FooterController'
import { NavigationController } from './NavigationController'
import { SpeakerArchiveController } from './SpeakerArchiveController'
import { HeadingController } from './HeadingController'
import { AnchorController } from './AnchorController'
import { ErrorController } from './ErrorController'
import { TimetableController } from './TimetableController'
import { AwardController } from './AwardController'

export interface Controller {
  dispose?(): void

  resize?(): void

  show?(animate?: boolean): Promise<void>

  hide?(): Promise<void>

  update?(time: number): void

  load?(): Promise<void>

  scroll?(): void
}

export class App extends EventEmitter {
  private readonly parser: DOMParser = new DOMParser()
  private readonly previewRef: string | undefined
  private readonly hasPreview: boolean
  private readonly observer: ResizeObserver
  private readonly scrollPositions = new Map<string, number>()

  private controllers: Controller[] = []
  private isBodyFixed = true
  private needsScrollUpdate = false

  public readonly intersectionObserver: IntersectionObserver
  public readonly router: Router

  public pointerX = 0
  public pointerY = 0

  public scrollY = 0
  public isDesktop = false
  private main: HTMLElement

  constructor() {
    super()
    this.update = this.update.bind(this)

    const mq = window.matchMedia('(min-width:1024px)')
    mq.addEventListener('change', () => (this.isDesktop = mq.matches))
    this.isDesktop = mq.matches

    //remove hash on load
    history.replaceState('', document.title, `${window.location.pathname}${window.location.search}`)

    const scrollbarWidth = getScrollbarWidth()

    document.body.style.setProperty('--scrollbar', `${scrollbarWidth}px`)
    this.main = document.querySelector('main') as HTMLElement

    this.router = new Router()
    this.router.on('change', this.onNavigate.bind(this))
    this.intersectionObserver = new IntersectionObserver((entries) => this.emit('intersect', entries), {
      threshold: 0.5,
    })

    const previewCookie = cookie.get(prismic.cookie.preview)
    this.previewRef = previewCookie
    this.hasPreview = previewCookie ? !!JSON.parse(previewCookie)[`${repo_name}.prismic.io`] : false

    this.observer = new ResizeObserver(this.onResize.bind(this))

    this.init()
  }

  async onFontLoad() {
    return new Promise<void>((resolve) => document.fonts.ready.then(() => resolve()))
  }

  async init(): Promise<void> {
    await Promise.all([this.onFontLoad()])

    if (this.hasPreview) {
      const html = await this.loadPage(window.location.pathname)
      if (html) this.updatePage(html)
    }

    this.setControllers()
    await Promise.all([...this.controllers.map((controller) => controller.load?.())])

    requestAnimationFrame(this.update)
    document.body.addEventListener('click', this.onDocumentClick.bind(this))
    document.body.addEventListener('pointermove', this.onPointerMove.bind(this))
    window.addEventListener('scroll', this.onScroll.bind(this))

    this.observer.observe(document.body)
    document.body.classList.add(styles.Visible)
    this.controllers.forEach((controller) => controller.show?.(true))
    this.unsetBodyFixed()
  }

  onPointerMove(event: PointerEvent) {
    this.pointerX = event.x
    this.pointerY = event.y
  }

  onDocumentClick(event: MouseEvent): void {
    let target: HTMLElement = event.target as HTMLElement

    while (target && target.parentNode) {
      if (target.tagName === 'A') {
        const { origin, pathname, hash, search } = new URL((target as HTMLAnchorElement).href)
        if (origin === window.location.origin && !(hash && pathname === window.location.pathname)) {
          event.preventDefault()
          this.router.push(pathname, hash, search)
        }
        break
      }
      target = target.parentNode as HTMLElement
    }
  }

  async onNavigate(route: string): Promise<void> {
    this.setBodyFixed()

    const [html] = await Promise.all([
      this.loadPage(route),
      ...this.controllers.map((controller) => controller.hide?.()).filter(Boolean),
    ])

    this.controllers.forEach((controller) => controller.dispose?.())

    this.updatePage(html)

    this.setControllers()

    await Promise.all([...this.controllers.map((controller) => controller.load?.())])

    this.onResize()

    this.controllers.forEach((controller) => controller.show?.(false))

    this.unsetBodyFixed()

    const scrollPos = this.scrollPositions.get(this.router.activeRoute) || 0
    window.scrollTo(0, scrollPos)
  }

  async loadPage(route?: string): Promise<string> {
    if (this.hasPreview && this.previewRef) {
      return await getPreview(this.previewRef, route)
    } else {
      const response = await fetch(`${route}index.html`)
      return await response.text()
    }
  }

  updatePage(html: string): void {
    const doc = this.parser.parseFromString(html, 'text/html')
    const mainNode = doc.querySelector('main') as HTMLElement

    document.title = doc.title
    document.documentElement.lang = doc.documentElement.lang

    window.scrollTo(0, 0)
    this.scrollY = 0

    this.main?.replaceWith(mainNode)
    this.main = document.querySelector('main') as HTMLElement
  }

  setControllers(): void {
    this.controllers = [
      ...this.getNodes(navigationStyles.Main).map((node) => new NavigationController(node)),
      ...this.getNodes(introStyles.Main).map((node) => new IntroController(node, this)),
      ...this.getNodes(footerStyles.Main).map((node) => new FooterController(node, this)),
      ...this.getNodes(speakerStyle.Main).map((node) => new SpeakerController(node, this)),
      ...this.getNodes(speakersStyle.Main).map((node) => new SpeakersController(node, this)),
      ...this.getNodes(speakerArchiveStyles.Main).map((node) => new SpeakerArchiveController(node, this)),
      ...this.getNodes(anchorStyles.Main).map((node) => new AnchorController(node)),
      ...this.getNodes(headingStyles.Main).map((node) => new HeadingController(node, this)),
      ...this.getNodes(errorStyles.Main).map((node) => new ErrorController(node, this)),
      ...this.getNodes(timeTableStyles.Main).map((node) => new TimetableController(node, this)),
      ...this.getNodes(awardStyles.Main).map((node) => new AwardController(node, this)),
    ]
  }

  getNodes(className: string): HTMLElement[] {
    return Array.from(document.body?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
  }

  setBodyFixed(): void {
    if (this.isBodyFixed) return
    this.isBodyFixed = true
    this.scrollY = window.scrollY
  }

  unsetBodyFixed(): void {
    if (!this.isBodyFixed) return
    this.isBodyFixed = false
    window.scrollTo(0, this.scrollY)
  }

  onResize(): void {
    this.controllers.forEach((controller) => controller.resize?.())
  }

  onScroll(): void {
    this.needsScrollUpdate = true
  }

  update(time: number): void {
    requestAnimationFrame(this.update)

    if (this.needsScrollUpdate && !this.isBodyFixed) {
      this.needsScrollUpdate = false
      this.scrollY = window.scrollY
      this.controllers.forEach((controller) => controller.scroll?.())
      this.scrollPositions.set(this.router.activeRoute, this.scrollY)
    }

    this.controllers.forEach((controller) => controller.update?.(time))
  }
}
