import { App, Controller } from './App'
import styles from './Heading.module.css'

export class HeadingController implements Controller {
  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)
    this.app.on('intersect', this.onIntersect)
  }

  async show() {
    this.app.intersectionObserver.observe(this.node)
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.node === entry.target) {
        this.node.classList.add(styles.Visible)
      }
    })
  }

  dispose() {
    this.app.removeListener('intersect', this.onIntersect)
    this.app.intersectionObserver.unobserve(this.node)
  }
}
