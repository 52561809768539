import {
  AdditiveBlending,
  BufferGeometry,
  MathUtils,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  PerspectiveCamera,
  Plane,
  PlaneGeometry,
  PointLight,
  Raycaster,
  Scene,
  ShaderMaterial,
  Sprite,
  SpriteMaterial,
  SRGBColorSpace,
  TextureLoader,
  Vector2,
  Vector3,
  Vector4,
  WebGLRenderer,
  WebGLRenderTarget,
} from 'three'
import { App } from './App'
import styles from './Void.module.css'
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass.js'
import particle from '../assets/images/particle.png'
import boxVert from './box.vert'
import boxFrag from './box.frag'
import outputVert from './output.vert'
import outputFrag from './output.frag'
import { Easing, Group, Tween } from '@tweenjs/tween.js'

export class VoidController {
  private readonly pointer = new Vector2()
  private readonly renderer: WebGLRenderer
  private readonly scene = new Scene()
  private readonly camera = new PerspectiveCamera()
  private readonly rayCaster = new Raycaster()
  private readonly mesh: Mesh<BufferGeometry, MeshPhongMaterial>
  private readonly bgMesh: Mesh<BufferGeometry, ShaderMaterial>
  private readonly intersection = new Vector3()
  private readonly renderTarget: WebGLRenderTarget
  private readonly skewMatrix = new Matrix4()
  private readonly skew = new Vector2()
  private readonly skewTarget = new Vector2()
  private readonly geometry: BufferGeometry
  private readonly bounds = new Vector4(-1, 1, -1, 1)
  private readonly group = new Group()
  private readonly quad: FullScreenQuad
  private readonly resizeObserver: ResizeObserver

  private readonly pointLight?: PointLight
  private readonly plane?: Plane
  private readonly ball?: Sprite

  private width = 0
  private height = 0
  private left = 0
  private top = 0
  private aspect = 1
  private vFov = 1
  private hFov = 1
  private scrollRatio = 0
  private depth = 0.1

  private needsUpdate = false

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
    private enableBall = false,
    lightness = 1,
  ) {
    this.onMouseMove = this.onMouseMove.bind(this)
    const canvas = this.node.querySelector(`.${styles.Canvas}`) as HTMLCanvasElement

    this.renderer = new WebGLRenderer({ antialias: true, canvas })
    this.camera.position.z = 10

    this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
    this.renderer.outputColorSpace = SRGBColorSpace

    this.renderTarget = new WebGLRenderTarget(this.width, this.height)

    this.scene.add(this.camera)

    const px = new PlaneGeometry()
    const nx = new PlaneGeometry()
    const py = new PlaneGeometry()
    const ny = new PlaneGeometry()

    px.rotateY(Math.PI * -0.5)
    px.rotateX(Math.PI * 0.5)
    px.translate(0.5, 0, -0.5)
    nx.rotateY(Math.PI * 0.5)
    nx.rotateX(Math.PI * 0.5)
    nx.translate(-0.5, 0, -0.5)
    py.rotateX(Math.PI * 0.5)
    py.translate(0, 0.5, -0.5)
    ny.rotateX(Math.PI * -0.5)
    ny.rotateY(Math.PI)
    ny.translate(0, -0.5, -0.5)

    this.geometry = mergeGeometries([px, nx, py, ny])

    const material = new ShaderMaterial({
      vertexShader: boxVert,
      fragmentShader: boxFrag,
      uniforms: {
        depth: { value: 1 },
      },
    })

    this.bgMesh = new Mesh(this.geometry, material)
    this.mesh = new Mesh(this.geometry, new MeshPhongMaterial({ depthTest: false, blending: AdditiveBlending }))

    this.scene.add(this.bgMesh)
    this.scene.add(this.mesh)

    if (this.enableBall) {
      this.pointLight = new PointLight()
      this.pointLight.intensity = 0.75
      this.scene.add(this.pointLight)

      this.ball = new Sprite(new SpriteMaterial({ blending: AdditiveBlending, transparent: true, opacity: 0 }))
      this.scene.add(this.ball)
      this.plane = new Plane(new Vector3(0, 0, 1), 2)
    }

    this.quad = new FullScreenQuad(
      new ShaderMaterial({
        vertexShader: outputVert,
        fragmentShader: outputFrag,
        uniforms: {
          diffuse: { value: this.renderTarget.texture },
          lightness: { value: lightness },
        },
      }),
    )

    this.resizeObserver = new ResizeObserver(this.resize.bind(this))
    this.resizeObserver.observe(this.node)
    this.node.addEventListener('mousemove', this.onMouseMove)

    this.resize()
  }

  onMouseMove(event: MouseEvent) {
    this.pointer.x = ((event.x - this.left) / this.width) * 2 - 1
    this.pointer.y = -((event.y - this.top + this.app.scrollY) / this.height) * 2 + 1
    this.needsUpdate = true
  }

  async load() {
    this.resize()
    if (this.ball) {
      this.ball.material.map = await new TextureLoader().loadAsync(particle)
      this.ball.material.needsUpdate = true
    }
  }

  async showBall() {
    return new Promise((resolve) => {
      new Tween({ opacity: 0, scale: 0.5 }, this.group)
        .to({ opacity: 1, scale: 1 }, 1000)
        .easing(Easing.Cubic.Out)
        .onUpdate(({ opacity, scale }) => {
          if (this.ball) {
            this.ball.material.opacity = opacity
            this.ball.scale.set(scale, scale, scale)
          }
        })
        .onComplete(resolve)
        .start()
    })
  }

  async animateDepth(depth: number) {
    return new Promise((resolve) => {
      new Tween({ depth: this.depth }, this.group)
        .to({ depth }, 3000)
        .easing(Easing.Exponential.Out)
        .onUpdate(({ depth }) => (this.depth = depth))
        .onComplete(resolve)
        .start()
    })
  }

  scroll() {
    const scroll = this.app.scrollY - this.top
    this.scrollRatio = scroll / this.height
  }

  resize() {
    const { left, top, width, height } = this.node.getBoundingClientRect()
    this.left = left
    this.top = top + this.app.scrollY
    this.width = width
    this.height = height

    this.aspect = this.width / this.height
    this.camera.aspect = this.aspect
    this.camera.updateProjectionMatrix()

    this.vFov = (this.camera.position.z * this.camera.getFilmHeight()) / this.camera.getFocalLength()
    this.hFov = this.vFov * this.camera.aspect

    this.renderTarget.setSize(width, height)
    this.renderer.setSize(width, height)
  }

  dispose() {
    this.resizeObserver.disconnect()
    this.group.removeAll()
    this.renderTarget.dispose()

    this.ball?.material.map?.dispose()
    this.ball?.geometry.dispose()

    this.bgMesh.material.dispose()
    this.mesh.material.dispose()
    this.geometry.dispose()

    this.scene.children.forEach((child) => this.scene.remove(child))
    this.renderer.dispose()
  }

  update(time: number) {
    this.group.update(time)

    if (this.needsUpdate && this.plane) {
      this.needsUpdate = false
      this.rayCaster.setFromCamera(this.pointer, this.camera)
      this.rayCaster.ray.intersectPlane(this.plane, this.intersection)
    }

    this.skewTarget.set(this.pointer.x * -0.25, this.pointer.y * -0.25)

    const minSize = Math.min(this.hFov, this.vFov)
    const z = Math.sin(time * 0.0001) * Math.cos(time * 0.0005) * 0.2 - this.depth * 0.2

    this.bgMesh.scale.set(this.hFov, this.vFov, this.depth)
    this.mesh.scale.set(this.hFov, this.vFov, this.depth)

    this.skewMatrix.identity()
    this.skewMatrix.makeShear(0, 0, 0, 0, this.skew.x * -1, this.skew.y * -1)
    this.geometry.applyMatrix4(this.skewMatrix)

    this.skew.x += (this.skewTarget.x - this.skew.x) * 0.02
    this.skew.y += (this.skewTarget.y + this.scrollRatio * 0.2 - this.skew.y) * 0.02

    this.skewMatrix.makeShear(0, 0, 0, 0, this.skew.x, this.skew.y)
    this.geometry.applyMatrix4(this.skewMatrix)

    if (this.ball && this.pointLight) {
      const space = minSize * 0.015
      const skewOffsetX = MathUtils.lerp(0, this.skew.x * -1 * this.hFov, Math.abs(z) / this.depth)
      const skewOffsetY = MathUtils.lerp(0, this.skew.y * -1 * this.vFov, Math.abs(z) / this.depth)

      this.bounds.set(
        this.hFov * -0.5 + skewOffsetX + space,
        this.hFov * 0.5 + skewOffsetX - space,
        this.vFov * -0.5 + space + skewOffsetY,
        this.vFov * 0.5 - space + skewOffsetY,
      )

      this.intersection.x = MathUtils.clamp(this.intersection.x, this.bounds.x, this.bounds.y)
      this.intersection.y = MathUtils.clamp(this.intersection.y, this.bounds.z, this.bounds.w)

      this.ball.scale.set(1, 1, 1).multiplyScalar(minSize * 0.1)
      this.ball.position.x += (this.intersection.x - this.ball.position.x) * 0.02
      this.ball.position.y += (this.intersection.y - this.ball.position.y) * 0.02
      this.ball.position.z = z

      this.pointLight.distance = minSize * 3
      this.pointLight.position.copy(this.ball.position)
    }

    this.renderer.setRenderTarget(this.renderTarget)
    this.renderer.render(this.scene, this.camera)
    this.renderer.setRenderTarget(null)
    this.quad.render(this.renderer)
  }
}
