.Main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100svh;
  background: black;
}

.Container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
