import { App, Controller } from './App'
import styles from './Speakers.module.css'

export class SpeakersController implements Controller {
  private readonly links: NodeListOf<HTMLElement>
  private readonly items: NodeListOf<HTMLElement>

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onClick = this.onClick.bind(this)
    this.onIntersect = this.onIntersect.bind(this)

    this.links = this.node.querySelectorAll(`.${styles.Link}`) as NodeListOf<HTMLElement>
    this.items = this.node.querySelectorAll(`.${styles.Item}`) as NodeListOf<HTMLElement>
    this.links.forEach((link) => link.addEventListener('click', this.onClick))

    this.app.on('intersect', this.onIntersect)
    this.app.intersectionObserver.observe(this.node)
  }

  onClick(event: Event) {
    const id = (event.currentTarget as HTMLElement)?.dataset.id

    this.links.forEach((link) =>
      link.dataset.id === id ? link.classList.add(styles.Active) : link.classList.remove(styles.Active),
    )

    this.items.forEach((item) =>
      item.dataset.id === id ? item.classList.add(styles.Visible) : item.classList.remove(styles.Visible),
    )
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target === this.node) {
        this.node.classList.add(styles.Visible)
      }
    })
  }

  dispose() {
    this.links.forEach((link) => link.removeEventListener('click', this.onClick))
    this.app.removeListener('intersect', this.onIntersect)
    this.app.intersectionObserver.unobserve(this.node)
  }
}
