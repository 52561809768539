.Main {
  font-family: var(--title-font);
  font-size: var(--title-size);
  line-height: 1;
  overflow: hidden;
}

.Animation {
  transform: translateX(-100%) scale(0.8);
  opacity: 0;
  transition:
    transform 900ms var(--easing),
    opacity 900ms var(--easing);
  transition-delay: var(--delay);
  will-change: transform, opacity;
}

.Visible .Animation {
  opacity: 1;
  transform: translateX(0) scale(1);
}
