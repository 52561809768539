#define GLSLIFY 1
uniform sampler2D diffuse;
uniform float lightness;
varying vec2 vUv;

float random(vec2 p) {
    vec2 K1 = vec2(
    23.14069263277926, // e^pi (Gelfond's constant)
    2.665144142690225// 2^sqrt(2) (Gelfond–Schneider constant)
    );
    return fract(cos(dot(p, K1)) * 12345.6789);
}

void main() {
    vec4 color = texture2D(diffuse, vUv);
    vec2 uvRandom = vUv;

    uvRandom.y *= random(vec2(uvRandom.y));
    color.rgb += random(uvRandom) * 0.075;
    color.rgb *= vec3(lightness);

    gl_FragColor = color;
}

