#define GLSLIFY 1
uniform float depth;
varying float vZ;

float exponentialIn(float t) {
    return t == 0.0 ? t : pow(2.0, 10.0 * (t - 1.0));
}

float cubicIn(float t) {
    return t * t * t;
}

float circularIn(float t) {
    return 1.0 - sqrt(1.0 - t * t);
}

void main(){
    float d = cubicIn(1.0 - vZ);
    gl_FragColor = vec4(d, d, d, 1.0);
//    gl_FragColor = vec4(1.0, 0.0, 1.0, 1.0);
}