.Body {
  --barHeight: 10vh;
  background: white;
  overflow-x: hidden;
  font-family: var(--main-font), sans-serif;
  opacity: 0;
}

.Visible {
  opacity: 1;
}
