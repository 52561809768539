import { Controller } from './App'
import styles from './Navigation.module.css'

export class NavigationController implements Controller {
  private button: HTMLElement
  private close: HTMLElement
  private bar: HTMLElement
  private links: NodeListOf<HTMLElement>
  private needsUpdate = false
  private sticky = false

  constructor(private readonly node: HTMLElement) {
    this.toggleMenu = this.toggleMenu.bind(this)
    this.onClick = this.onClick.bind(this)

    this.button = this.node.querySelector(`.${styles.Button}`) as HTMLElement
    this.close = this.node.querySelector(`.${styles.Close}`) as HTMLElement
    this.bar = this.node.querySelector(`.${styles.Bar}`) as HTMLElement
    this.links = this.node.querySelectorAll(`.${styles.Link}`) as NodeListOf<HTMLElement>

    this.links.forEach((link) => link.addEventListener('click', this.onClick))
    this.button.addEventListener('click', this.toggleMenu)
  }

  toggleMenu() {
    this.node.classList.toggle(styles.Open)
  }

  onClick() {
    this.node.classList.remove(styles.Open)
  }

  scroll() {
    this.needsUpdate = true
  }

  update(time: number) {
    if (this.needsUpdate) {
      this.needsUpdate = false
      const { top } = this.bar.getBoundingClientRect()
      if (!this.sticky && top <= 0) {
        this.sticky = true
        this.node.classList.add(styles.Sticky)
      } else if (this.sticky && top > 0) {
        this.sticky = false
        this.node.classList.remove(styles.Sticky)
      }
    }
  }

  resize() {
    const { height } = this.bar.getBoundingClientRect()
    document.body.style.setProperty('--barHeight', `${height}px`)
  }

  dispose() {
    this.button.removeEventListener('click', this.toggleMenu)
    this.close.removeEventListener('click', this.toggleMenu)
  }
}
