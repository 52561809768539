import { App, Controller } from './App'
import { VoidController } from './VoidController'
import { delay } from '../utils/delay'

export class IntroController implements Controller {
  private readonly void: VoidController
  private inView = false

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)
    this.void = new VoidController(this.node, app, true)

    this.app.on('intersect', this.onIntersect)
    this.app.intersectionObserver.observe(this.node)
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.target === this.node) {
        this.inView = entry.isIntersecting
      }
    })
  }

  async load() {
    await this.void.load()
  }

  async show() {
    this.void.animateDepth(10)
    await delay(1000)
    this.void.showBall()
  }

  async hide() {}

  scroll() {
    this.void.scroll()
  }

  resize() {
    this.void.resize()
  }

  dispose() {
    this.void.dispose()
    this.app.removeListener('intersect', this.onIntersect)
    this.app.intersectionObserver.unobserve(this.node)
  }

  update(time: number) {
    if (!this.inView) return
    this.void.update(time)
  }
}
