import styles from './Speaker.module.css'
import { App, Controller } from './App'

export class SpeakerController implements Controller {
  private currentSlide = 0
  private slides: NodeListOf<HTMLElement>
  private arrowLeft: HTMLElement
  private arrowRight: HTMLElement
  private hiddenItems: Element[]

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.slides = this.node.querySelectorAll(`.${styles.Image}`) as NodeListOf<HTMLElement>

    this.prevSlide = this.prevSlide.bind(this)
    this.nextSlide = this.nextSlide.bind(this)

    this.arrowLeft = this.node.querySelector(`.${styles.ArrowLeft}`) as HTMLElement
    this.arrowRight = this.node.querySelector(`.${styles.ArrowRight}`) as HTMLElement

    this.arrowLeft?.addEventListener('click', this.prevSlide)
    this.arrowRight?.addEventListener('click', this.nextSlide)

    this.hiddenItems = Array.from(this.node.querySelectorAll(`.${styles.NotVisible}`))
    this.onIntersect = this.onIntersect.bind(this)

    this.app.on('intersect', this.onIntersect)
    this.app.intersectionObserver.observe(this.node)
    this.showSlide(this.currentSlide)
  }

  async show() {
    this.hiddenItems.forEach((item) => this.app.intersectionObserver.observe(item))
  }

  showSlide(index: number) {
    if (index >= this.slides.length) this.currentSlide = 0
    else if (index < 0) this.currentSlide = this.slides.length - 1
    else this.currentSlide = index
    this.slides.forEach((slide, index) => (slide.style.display = this.currentSlide === index ? 'block' : 'none'))
  }

  prevSlide() {
    this.showSlide(this.currentSlide - 1)
  }

  nextSlide() {
    this.showSlide(this.currentSlide + 1)
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (this.hiddenItems.includes(entry.target) && entry.isIntersecting) {
        entry.target.classList.add(`${styles.Animate}`)
      }
    })
  }

  dispose() {
    this.arrowLeft?.removeEventListener('click', this.prevSlide)
    this.arrowRight?.removeEventListener('click', this.nextSlide)
    this.app.removeListener('intersect', this.onIntersect)
    this.hiddenItems.forEach((item) => this.app.intersectionObserver.unobserve(item))
  }
}
