import { App, Controller } from './App'
import styles from './Award.module.css'

export class AwardController implements Controller {
  private items: Element[]

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)

    this.items = Array.from(this.node.querySelectorAll(`.${styles.NotVisible}`))

    this.app.on('intersect', this.onIntersect)
    this.items.forEach((item) => this.app.intersectionObserver.observe(item))
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (this.items.includes(entry.target) && entry.isIntersecting) {
        entry.target.classList.add(`${styles.Animate}`)
      }
    })
  }

  dispose() {
    this.app.removeListener('intersect', this.onIntersect)
    this.items.forEach((item) => this.app.intersectionObserver.unobserve(item))
  }
}
