import cookie from 'js-cookie'

import { preview_document_id_cookie, repo_name } from '../../config.json'
import { getData } from '../lib/primsic'
import { PageDocument } from '../types/types.generated'
import * as prismic from '@prismicio/client'
import { routeResolver } from '../lib/routeResolver'

const isPage = (doc: any): doc is PageDocument => doc.type === 'page'

const endpoint = prismic.getRepositoryEndpoint(repo_name)
export const client = prismic.createClient(endpoint)

export async function getPreview(ref: string, route?: string): Promise<string> {
  const { pages } = await getData(client, ref)
  const allDocs = [...pages]
  const routes = [...pages.map((page) => `${routeResolver(page)}`), '/unpublished/']

  let doc

  if (route === '/unpublished/') {
    const id = cookie.get(preview_document_id_cookie)
    doc = allDocs.find((page) => page.id === id)
  } else {
    const routeArray = route ? route.split('/') : []
    const uid = routeArray[1] || 'index'
    doc = allDocs.find((page) => page.uid === uid)
  }

  if (doc && route) {
    if (isPage(doc)) {
      const page = doc
      const {} = page

      const route = `${routeResolver(page)}`

      return ''
    }
  }

  return ''
}
