import { Client } from '@prismicio/client'
import {
  FooterDocument,
  NavigationDocument,
  PageDocument,
  PastSpeakerDocument,
  SettingsDocument,
  SpeakerDocument,
  TimetableDocument,
} from '../types/types.generated'

export async function getData(
  client: Client,
  ref?: string,
): Promise<{
  pages: PageDocument[]
  speakers: SpeakerDocument[]
  pastSpeakers: PastSpeakerDocument[]
  footer: FooterDocument
  navigation: NavigationDocument
  timetable: TimetableDocument
  settings: SettingsDocument
}> {
  const [pages, speakers, pastSpeakers, footer, navigation, timetable, settings] = await Promise.all([
    client.getAllByType<PageDocument>('page', { ref }),
    client.getAllByType<SpeakerDocument>('speaker', { ref }),
    client.getAllByType<PastSpeakerDocument>('past_speaker', { ref }),
    client.getSingle<FooterDocument>('footer', { ref }),
    client.getSingle<NavigationDocument>('navigation', { ref }),
    client.getSingle<TimetableDocument>('timetable', { ref }),
    client.getSingle<SettingsDocument>('settings', { ref }),
  ])

  return {
    pages,
    speakers,
    pastSpeakers,
    footer,
    navigation,
    timetable,
    settings,
  }
}
