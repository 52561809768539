.Main {
  background-color: black;
  color: white;
  padding: var(--space-large) var(--space-small);
  display: grid;
  gap: var(--space-large);
}

.Main h1 {
  color: white;
}

.Container {
  display: grid;
  gap: var(--space-medium);
}

.Item {
  --timeline: cubic-bezier(0.18, 0.95, 0.7, 1);
  display: grid;
  opacity: 0;
  transform: translateY(15vh);
  transition:
    opacity 0.5s var(--timeline),
    transform 0.5s var(--timeline);
}

.ItemVisible {
  opacity: 1;
  transform: translateY(0vh);
}

.Head {
  display: grid;
  justify-items: start;
  gap: 2.5vw;
}

.Day {
  font-family: var(--title-font);
  color: black;
  background-color: white;
  padding: 0.25em 0.5em;
  line-height: 1;
  font-size: 2rem;
}

.Title {
  font-family: var(--title-font);
  font-size: 2rem;
  line-height: 1;
}

.Schedule {
  padding: 12.5vw 0;
}

.Time {
  justify-self: stretch;
  display: grid;
  line-height: 1.4;
  grid-template-columns: 40% minmax(0, 1fr);
  gap: 2.5vw;
}

.Link:hover {
  opacity: 0.8;
}

@media (min-width: 1024px) {
  .Main {
    grid-template-columns: repeat(2, minmax(0, 50%));
    gap: var(--space-small);
    padding: var(--space-large);
    justify-content: space-between;
    align-items: start;
  }

  .Item {
    align-items: start;
    grid-template-columns: 40% minmax(0, 1fr);
    gap: var(--space-small);
  }

  .Head {
    gap: 0.5vw;
  }

  .Schedule {
    padding: 0;
  }
}
