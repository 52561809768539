@keyframes showLinks {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }

  100% {
    transform: translateX(0vh);
    opacity: 1;
  }
}

.Main {
  position: sticky;
  top: 0;
  width: 100%;
  font-size: 1rem;
  line-height: 1.1;
  font-family: var(--title-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: grid;
  grid-template-rows: minmax(0, 1fr) min-content;
  align-items: end;
  pointer-events: none;
  z-index: 100;
  color: white;
  mix-blend-mode: difference;
}

.Full {
  top: calc((100svh - var(--barHeight)) * -1);
  height: 100svh;
  mix-blend-mode: unset;
}

.Sticky {
  mix-blend-mode: difference;
}

.Main.Open {
  position: fixed;
  top: 0;
  height: 100svh;
  mix-blend-mode: unset;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.Full.Open {
  position: sticky;
}

.Menu {
  padding: 10vw var(--space-small);
  box-sizing: border-box;
  grid-template-rows: minmax(0, 1fr) min-content;
  gap: 10vw;
  align-self: stretch;
  display: none;
}

.Main.Open .Menu {
  display: grid;
}

.Bar {
  display: flex;
  box-sizing: border-box;
  padding: var(--space-small);
  justify-content: space-between;
  gap: 2vw;
}

.Links {
  display: grid;
  gap: var(--space-small);
  align-content: center;
  font-size: 2rem;
}

.Link {
  position: relative;
  text-decoration: none;
  display: flex;
  gap: 0.25em;
  pointer-events: auto;
}

.Animation {
  --delay: 0;
}

.Open .Animation {
  animation-duration: 0.5s;
  animation-delay: calc(150ms + var(--delay));
  animation-timing-function: cubic-bezier(0.18, 0.95, 0.2, 1);
  animation-fill-mode: both;
  animation-name: showLinks;
}

.Link.Hidden {
  display: none;
}

.Icon {
  display: inline-block;
  white-space: nowrap;
  width: 1.25em;
  letter-spacing: 0;
}

.Main.Open .Button {
  display: none;
}

.Main.Open .Close {
  display: flex;
}

@media (min-width: 1024px) {
  .Main {
    padding: 0 2.5vw;
    grid-template-rows: max-content;
    grid-template-columns: max-content minmax(0, 1fr);
    align-content: end;
    justify-content: space-between;
    gap: var(--space-small);
  }

  .Main.Open {
    background-color: transparent;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .Menu {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    order: 1;
    padding: 2.5vw 0;
    justify-items: end;
  }

  .Bar {
    padding: 2.5vw 0;
  }

  .Links {
    font-size: inherit;
    grid-auto-flow: column;
    order: 1;
    gap: 2.5vw;
    pointer-events: auto;
  }

  .Button {
    display: none;
    pointer-events: auto;
  }
}
