.Main {
    position: absolute;
    width: 100%;
    height: 100%;
}

.Canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}