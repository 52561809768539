export const resolver = (uid: string, type: string): string => {
  let route = '/'

  if (type === 'page') {
    route = uid === 'index' ? '/' : `/${uid}/`
  } else if (type === 'speaker') {
    route = `/speaker/${uid}/`
  }

  //static generation or existing route for preview
  if (typeof window === 'undefined' || window.__ROUTES__.includes(route)) {
    return route
  }

  //route unpublished documents to generic page
  return `/unpublished/`
}
