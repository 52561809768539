import { App, Controller } from './App'
import { VoidController } from './VoidController'
import styles from './Footer.module.css'

export class FooterController implements Controller {
  private readonly void: VoidController
  private readonly container: HTMLElement
  private inView = false

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)

    this.container = this.node.querySelector(`.${styles.Container}`) as HTMLElement
    this.void = new VoidController(this.container, app, false, 0.35)

    this.app.on('intersect', this.onIntersect)
    this.app.intersectionObserver.observe(this.container)
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.target === this.container) {
        this.inView = entry.isIntersecting
        if (this.inView) {
          this.void.animateDepth(10)
        }
      }
    })
  }

  async load() {
    await this.void.load()
  }

  async show() {}

  async hide() {}

  scroll() {
    this.void.scroll()
  }

  resize() {
    this.void.resize()
  }

  dispose() {
    this.void.dispose()
    this.app.removeListener('intersect', this.onIntersect)
    this.app.intersectionObserver.unobserve(this.container)
  }

  update(time: number) {
    if (!this.inView) return
    this.void.update(time)
  }
}
